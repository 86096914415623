import React from "react";

import { Form } from "components/elements";
import { useDidSessionExpire } from "services/auth/hooks";
import { useAppConfig } from "context";
import { SupportPhoneLink } from "components/elements";

const incorrectPasswordMessage =
	"The username/password combination isn't right. Try entering it again or ";
const inactivityMessage =
	"You have been signed out due to inactivity. Please log in again.";
const lockedErrorMessage =
	"For your security, we locked your account. Check your email for instructions on how to unlock it.";
const unlockSuccessMessage = "Your account was unlocked successfully.";
const unlockFailureMessage = (
	<>
		We encountered an issue unlocking your account. Please try again later or
		call our Customer Support Team at <SupportPhoneLink /> .
	</>
);
const stagedAccountMessage =
	"You haven't finished online enrollment. Follow the steps to ";
const userNotFoundMessage =
	"We couldn’t find your account. Use the email associated with it or ";
const recoveryMessage = (
	<>
		There's an issue with your account. Call us at <SupportPhoneLink /> for help
		accessing it.
	</>
);

export function LoginFormError({
	hasError,
	isAccountLocked,
	unlockAccountSuccess,
	isAccountStaged,
	status,
}) {
	const isExpired = useDidSessionExpire();
	const { baseUrl } = useAppConfig();
	const errorMessage = (() => {
		if (status === "USER_NOT_FOUND") return userNotFoundMessage;
		if (status === "RECOVERY") return recoveryMessage;
		if (isAccountLocked) return lockedErrorMessage;
		if (unlockAccountSuccess === false) return unlockFailureMessage;
		if (isExpired) return inactivityMessage;
		if (isAccountStaged) return stagedAccountMessage;
		if (hasError) return incorrectPasswordMessage;
		if (unlockAccountSuccess === true) return unlockSuccessMessage;
		return "";
	})();

	const enrollLink = (
		<a
			href={`${baseUrl}/enroll`}
			style={{
				color: "#2371a5",
				textDecoration: "underline",
			}}
		>
			enroll in online access.
		</a>
	);

	const passwordResetLink = (
		<a
			href={`${baseUrl}/apps/account/signin/forgot-password`}
			style={{
				color: "#2371a5",
				textDecoration: "underline",
			}}
		>
			reset your password.
		</a>
	);

	return (
		<div style={{ color: "red" }}>
			{errorMessage === recoveryMessage ? (
				<div>{recoveryMessage}</div>
			) : errorMessage === stagedAccountMessage ? (
				<div>
					{stagedAccountMessage}
					{enrollLink}
				</div>
			) : errorMessage === userNotFoundMessage ? (
				<div>
					{userNotFoundMessage}
					{enrollLink}
				</div>
			) : hasError && status !== "USER_NOT_FOUND" ? (
				<div>
					{incorrectPasswordMessage}
					{passwordResetLink}
				</div>
			) : (
				<Form.Error message={errorMessage} />
			)}
		</div>
	);
}
