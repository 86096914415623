import { useState } from "react";
import { useLoginMutation } from "services/auth";

export function useLogin() {
	const [hasError, setHasError] = useState(false);
	const clearError = () => setHasError(false);

	const {
		data,
		isLoading,
		mutate: attemptLogin,
	} = useLoginMutation({
		showError: () => setHasError(true),
	});

	return {
		attemptLogin,
		clearError,
		hasError,
		isAccountLocked: data?.isAccountLocked,
		isAccountStaged: data?.isAccountStaged,
		isSubmitting: isLoading,
		status: data?.status,
	};
}
