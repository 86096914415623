import React from "react";
import { FormLabel } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { Field, Form, SubmitButton } from "components/elements";
import { useBreakpointEffect, useConditionalEffect } from "hooks";

import { useLogin } from "../useLogin";
import { LoginFormError } from "./LoginFormError";

const controlProps = { mt: 5 };

export function LoginForm({ savedUsername, unlockAccountSuccess }) {
	const {
		attemptLogin,
		clearError,
		hasError,
		isAccountLocked,
		isAccountStaged,
		isSubmitting,
		status,
	} = useLogin();
	const {
		formState: { errors, isDirty, isValid },
		handleSubmit,
		register,
		reset,
		setFocus,
		setValue,
	} = useForm({
		defaultValues: {
			rememberMe: !!savedUsername,
			username: savedUsername,
		},
		mode: "onChange",
	});

	useBreakpointEffect("lg", () => {
		setFocus("username");
	});
	useConditionalEffect(isAccountLocked, () => {
		reset({ password: "", rememberMe: false, username: "" });
	});

	const isSubmitDisabled =
		isAccountLocked || !isDirty || !isValid || unlockAccountSuccess === false;

	return (
		<Form onSubmit={handleSubmit(attemptLogin)} py={6}>
			<LoginFormError
				hasError={hasError}
				isAccountLocked={isAccountLocked}
				unlockAccountSuccess={unlockAccountSuccess}
				isAccountStaged={isAccountStaged}
				status={status}
			/>
			<Field
				controlProps={controlProps}
				error={errors?.username}
				id="username"
				isDisabled={isAccountLocked || unlockAccountSuccess === false}
				label="Email"
				onFocus={clearError}
				type="text"
				{...register("username", { required: "Please enter your username" })}
			/>
			<Field
				controlProps={controlProps}
				error={errors?.password}
				id="password"
				isDisabled={isAccountLocked || unlockAccountSuccess === false}
				label="Password"
				onFocus={clearError}
				type={"password"}
				{...register("password", { required: "Please enter your password" })}
			/>
			<Field
				defaultChecked={savedUsername}
				field="checkbox"
				fontWeight="normal"
				id="rememberMe"
				isDisabled={isAccountLocked}
				{...register("rememberMe")}
			>
				<span id="rememberMeLabel">Remember Me</span>
			</Field>
			<SubmitButton
				isDisabled={isSubmitDisabled}
				isLoading={isSubmitting}
				mt={3}
				id="signInButton"
			>
				Sign In
			</SubmitButton>
		</Form>
	);
}
