import { useQuery } from 'react-query'
import { axiosClient } from 'core'

async function getFundingAccounts() {
  const response = await axiosClient.get(`/acct/api/payment/methods`)
  return response.data.payload
}

function createFundingAccountsQuery(options = {}) {
  return {
    queryFn: () => getFundingAccounts(),
    queryKey: ['fundingAccounts'],
    ...options,
  }
}

export function useFundingAccounts(options = {}) {
  return useQuery({
    ...createFundingAccountsQuery(),
    retry: 5,
    ...options,
  })
}
